<template>
  <div class="home_top">
    <div class="inner">
      <div class="left_item">
        <h3>Welcome <br>to EasyKash！</h3>
        <p>EasyKash is your one-stop loan platform.</p>
        <div>
          <a href="">
            <img src="@/assets/images/loanImg/download_now.png" alt="">
          </a>
          <img src="@/assets/images/loanImg/Google-play.png" alt="">
        </div>
      </div>
      <div class="right_item">
        <img src="@/assets/images/loanImg/top_app.png" alt="">
      </div>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>

  .home_top{
    min-width: 1200px;
    height: 834px;
    background: #fff;
    position: relative;
    background: url("~@/assets/images/loanImg/top_bg.png") no-repeat top right;
    .inner{
      width: 1200px;
      margin: 0 auto;
      padding-top: 120px;
      .left_item{
        float: left;
        padding-top: 20px;
        h3{
          font-size: 36px;
          color: #333333;
          font-weight: 700;
          margin-bottom: 40px;
        }
        p{
          font-size: 16px;
          color: #666666;
          margin-bottom: 100px;
        }
        div{
          img{
            width: 202px;
            height: 59px;
            margin-right: 20px;
          }
        }
      }
      .right_item{
        float: right;
        img{
          width: 650px;
        }
      }
    }
  }

</style>
